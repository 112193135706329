export const PaymentMethodType = {
  MoMo: 0,

  ZaloPay: 1,

  CreditDebitCard: 2,

  Cash: 3,

  VNPay: 4,

  COD: 5,

  BankTransfer: 6,

};

export const PaymentStatus = {
  WAIT_FOR_PAY: "WAIT_FOR_PAY",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILED: "PAYMENT_FAILED",
};
