/**
 * Theme data - storage the information of the theme
 */
import _defaultConfig from "./default-store.config";
import { AboutUsCustomize } from "./pages/about-us/about-us.customize";
import { BlogCustomizePage } from "./pages/blog/blog-customize.page";
import { BlogDetailCustomizePage } from "./pages/blog/blog-detail-customize.page";
import { CheckoutCustomizes } from "./pages/checkout/components/checkout.customize";
import { ContactCustomizePage } from "./pages/contact/contact.customize";
import { HomeCustomizes } from "./pages/home/home.customize";
import { LoginCustomizes } from "./pages/login/login.customize";
import PaymentStatusPage from "./pages/payment-status/components/payment-status.component";
import { ProductDetailCustomize } from "./pages/product-detail/index.customize";
import ProductDetailPage from "./pages/product-detail/index.page";
import { ProductListCustomizes } from "./pages/productlist/components/product-list.customize";
import { ProfilePageCustomizes } from "./pages/profile-page/profile-page.customize";
import ReserveTable from "./pages/reserve-table/ReserveTable.page";
import { ReserveTableCustomizes } from "./pages/reserve-table/reserve-table-customize.component";
const { default: PageType } = require("./constants/page-type.constants");
const { default: Theme2Login } = require("./pages/login/login.page");
const { default: HomePage } = require("./pages/home/home.page");
const { default: CheckoutPage } = require("./pages/checkout/checkout.page");
const { default: ProductListPage } = require("./pages/productlist/product-list.page");
const { default: AboutUsPage } = require("./pages/about-us/about-us.page");
const { default: ContactPage } = require("./pages/contact/contact.page");
const { default: ProfilePage } = require("./pages/profile-page/profile-page.page");
const { default: BlogPage } = require("./pages/blog/blog.page");

const configStorage = localStorage.getItem("config");
const configJson = JSON.parse(configStorage);

const translateData = {
  homePage: "storeWebPage.homePage",
  productList: "storeWebPage.productList",
  productDetail: "storeWebPage.productDetail",
  comboDetail: "storeWebPage.comboDetail",
  booking: "storeWebPage.booking",
  cartPage: "storeWebPage.cartPage",
  checkoutPage: "storeWebPage.checkoutPage",
  registerPage: "storeWebPage.registerPage",
  loginPage: "storeWebPage.loginPage",
  forgotPasswordPage: "storeWebPage.forgotPasswordPage",
  myProfilePage: "storeWebPage.myProfilePage",
  contactPage: "storeWebPage.contactPage",
  aboutUsPage: "storeWebPage.aboutUsPage",
  blog: "storeWebPage.blog",
  blogDetail: "storeWebPage.blogDetail",
  reservation: "storeWebPage.reservation",
  paymentStatus: "storeWebPage.paymentStatus",
};

export const themeData = {
  id: "46565F44-C3E2-449D-8D58-3850A95FFBA7", // the static id generated by GoF&B system
  name: "Pho Viet", // theme name
  pages: [
    // the all pages of theme
    {
      id: PageType.HOME_PAGE, // page id - static data get from system
      name: translateData.homePage, // name of page
      component: HomePage, // main page
      customizes: HomeCustomizes, // customize objects
      path: "/home", // path to access to page from browser
      metaInfo: {
        title: "Trang chủ",
        description: "Trang chủ",
      },
    },
    {
      id: PageType.PRODUCT_LIST,
      name: translateData.productList,
      component: ProductListPage,
      customizes: ProductListCustomizes, // customize objects
      path: "/product-list",
      metaInfo: {
        title: "Danh sách sản phẩm",
        description: "Tất cả sản phẩm",
      },
    },
    {
      id: PageType.PRODUCT_LIST,
      name: translateData.productList,
      component: ProductListPage,
      customizes: ProductListCustomizes,
      path: "/product-list/:productCategoryId",
      metaInfo: {
        title: "Danh sách sản phẩm",
        description: "Tất cả sản phẩm",
      },
    },
    {
      id: PageType.PRODUCT_DETAIL,
      name: translateData.productDetail,
      component: ProductDetailPage,
      customizes: ProductDetailCustomize,
      path: "/product-detail/:productId",
      metaInfo: {
        title: "Chi tiết sản phẩm",
        description: "Chi tiết sản phẩm",
      },
    },
    {
      id: configJson?.customizeTheme ? PageType.COMBO_DETAIL : PageType.PRODUCT_DETAIL,
      name: translateData.comboDetail,
      component: ProductDetailPage,
      customizes: [],
      path: "/combo-detail/:comboType/:comboId",
      metaInfo: {
        title: "Chi tiết sản phẩm",
        description: "Chi tiết sản phẩm",
      },
    },
    {
      id: PageType.BOOKING,
      name: translateData.booking,
      component: HomePage,
      customizes: [],
      path: "/booking",
      metaInfo: {
        title: "Đặt lịch",
        description: "Đặt lịch",
      },
    },
    {
      id: PageType.CART_PAGE,
      name: translateData.cartPage,
      component: HomePage,
      customizes: [],
      path: "/cart",
      metaInfo: {
        title: "Đặt hàng",
        description: "Đặt hàng",
      },
    },
    {
      id: PageType.CHECKOUT_PAGE,
      name: translateData.checkoutPage,
      component: CheckoutPage,
      customizes: CheckoutCustomizes,
      path: "/checkout",
      metaInfo: {
        title: "Đơn hàng",
        description: "Đơn hàng",
      },
    },
    {
      id: PageType.REGISTER_PAGE,
      name: translateData.registerPage,
      component: Theme2Login,
      customizes: [],
      path: "/register",
      metaInfo: {
        title: "Đăng ký tài khoản",
        description: "Đăng ký tài khoản",
      },
    },
    {
      id: PageType.LOGIN_PAGE,
      name: translateData.loginPage,
      component: Theme2Login,
      customizes: LoginCustomizes, // customize objects
      path: "/login",
      metaInfo: {
        title: "Đăng nhập",
        description: "Đăng nhập",
      },
    },
    {
      id: PageType.FORGOT_PASSWORD_PAGE,
      name: translateData.forgotPasswordPage,
      component: HomePage,
      customizes: [],
      path: "/forgot-password",
      metaInfo: {
        title: "Quên mật khẩu",
        description: "Quên mật khẩu",
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: ProfilePage,
      customizes: ProfilePageCustomizes,
      path: "/my-profile",
      metaInfo: {
        title: "Hồ sơ của tôi",
        description: "Hồ sơ của tôi",
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: ProfilePage,
      customizes: ProfilePageCustomizes,
      path: "/my-profile/:index",
      metaInfo: {
        title: "Hồ sơ của tôi",
        description: "Hồ sơ của tôi",
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: ProfilePage,
      customizes: ProfilePageCustomizes,
      path: "/my-profile/:index/:orderId",
      metaInfo: {
        title: "Hồ sơ của tôi",
        description: "Hồ sơ của tôi",
      },
    },
    {
      id: PageType.CONTACT_PAGE,
      name: translateData.contactPage,
      component: ContactPage,
      customizes: ContactCustomizePage,
      path: "/contact",
      metaInfo: {
        title: "Liên hệ",
        description: "Liên hệ",
      },
    },
    {
      id: PageType.ABOUT_US_PAGE,
      name: translateData.aboutUsPage,
      component: AboutUsPage,
      customizes: AboutUsCustomize,
      path: "/about-us",
      metaInfo: {
        title: "About Us",
        description: "About Us",
      },
    },
    {
      id: PageType.BLOG_PAGE,
      name: translateData.blog,
      component: BlogPage,
      customizes: BlogCustomizePage,
      path: "/blog",
      metaInfo: {
        title: "Bài viết",
        description: "Bài viết",
      },
    },
    {
      id: PageType.BLOG_DETAIL_PAGE,
      name: translateData.blogDetail,
      component: BlogPage,
      customizes: BlogDetailCustomizePage,
      path: "/blog/:urlEncode",
      metaInfo: {
        title: "Bài viết",
        description: "Bài viết",
      },
    },
    {
      id: PageType.RESERVE_TABLE_PAGE,
      name: translateData.reservation,
      component: ReserveTable,
      customizes: ReserveTableCustomizes,
      path: "/reserve-table",
      metaInfo: {
        title: "Đặt bàn",
        description: "Đặt bàn",
      },
    },
    {
      id: PageType.PAYMENT_STATUS,
      name: translateData.paymentStatus,
      component: PaymentStatusPage,
      path: "/payment-status",
      metaInfo: {
        title: "Thanh toán",
        description: "Thanh toán",
      },
    },
  ],
};

export const themeToken = {
  fontFamily: "Montserrat",
};

export const getRouteMetaInfo = (routeId) => {
  const route = themeData.pages.find((r) => r.id === routeId);
  return route?.metaInfo ?? {};
};

export const defaultConfig = _defaultConfig;

// this property for admin scan
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  themeData,
  defaultConfig,
};
