export const areasDefault = [
  {
    id: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Khu sân vườn tầng trệt",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [
      {
        id: "a0118fcc-db30-497b-2b2e-08dbe4dfe258",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 1",
        numberOfSeat: 999,
        description:
          "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
      },
      {
        id: "f08a90e7-0e63-4b58-437d-08dbe5852c3c",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 2",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853269",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853268",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853267",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853266",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853265",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
      {
        id: "6bc470af-808e-40b6-21cd-08dbe5853264",
        areaId: "bf48f4d0-4577-4fc4-3274-08dbe4dfd860",
        name: "Table 3",
        numberOfSeat: 999,
        description:
          "<p>Lorem ipsum dolor sit amet consectetur. A luctus nulla fermentum mi platea et consequat non. Eu eu pulvinar sit nunc consequat. Consectetur pellentesque faucibus quis lorem posuere vel volutpat. Ultrices ma..</p>",
      },
    ],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed01",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Tầng 1",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed02",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Tầng 2",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed03",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Tầng 3",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed04",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Tầng thượng",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed05",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Khu VIP",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
  {
    id: "565f7ef6-796e-4b5f-e247-08dbe4dfed06",
    storeId: "c1cd134f-3eac-4d59-8bba-65f55cae9e80",
    storeBranchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    name: "Lorem ipsum dolor sit amet orem ipsum dolor sit amet Lorem ipsum dolor sit amet orem ipsum dolor sit",
    description:
      "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>",
    tables: [],
  },
];
export const branchDefault = {
  branchesByCustomerAddress: [
    {
      distance: 1000,
      lat: 10.793878,
      lng: 106.6740005,
      branchAddress: "45 Trần Hữu Trang, Phường 11, Quận Phú Nhuận, Hồ Chí Minh",
      branchName: "Tân Bình",
      branchId: "02be0c7d-352c-43f8-81c3-14f988137061",
    },
  ],
};
