export const CURRENCY_CODE = {
  VND: "VND",
};

export const currency = {
  vnd: "VND",
  d: "đ",
};

export const DateFormat = {
  DD_MM_YYYY: "DD/MM/YYYY",
};

export const DefaultShippingName = {
  Home: "Home",
  Work: "Work",
};

export const paramIsLoadData = "isLoadData"; //data field to identify first scan Qr Order
