export const PaymentMethodType = {
  /// <summary>
  /// Cash
  /// </summary>
  Cash: 3,

  /// <summary>
  /// Momo
  /// </summary>
  Momo: 0,

  /// <summary>
  /// BankTransfer
  /// </summary>
  BankTransfer: 6,
};
