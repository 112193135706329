import { useEffect, useRef } from "react";
import { useState } from "react";
import i18n from "../../utils/i18n";
import SelectLanguage from "../components/SelectLanguage/SelectLanguage";
import ListLanguage from "../components/ListLanguage/ListLanguage";
import { listDefaultLanguage } from "../components/change-language/list-language";
import { setLanguageSession } from "../../modules/session/session.actions";
import { useDispatch } from "react-redux";

const SelectLanguageContainer = (props) => {
  const { className = "" } = props;
  const [languageSelected, setLanguageSelected] = useState(i18n?.language);
  const [isShowListLanguage, setIsShowListLanguage] = useState(false);
  const dispatch = useDispatch();
  const prevLangRef = useRef(i18n.language);

  useEffect(() => {
    const langCode = localStorage.getItem("i18nextLng");
    const lang = listDefaultLanguage.find((i) => i.languageCode === langCode) || listDefaultLanguage[0];
    if (lang.languageCode !== prevLangRef.current) {
      i18n.changeLanguage(lang.languageCode);
      setLanguageSelected(lang);
      dispatch(setLanguageSession({ default: lang, list: listDefaultLanguage }));
      prevLangRef.current = lang.languageCode;
    } else {
      setLanguageSelected(lang);
    }
  }, [i18n.language]);

  function handleClickSelectLanguage() {
    setIsShowListLanguage(true);
  }

  function handleChooseLanguage(lang) {
    setLanguageSelected(lang);
    i18n.changeLanguage(lang.languageCode);
    setIsShowListLanguage(false);
  }

  return (
    <>
      <SelectLanguage
        onClick={handleClickSelectLanguage}
        languages={listDefaultLanguage}
        currentLang={languageSelected}
        isMobileMode={true}
        className={className}
      />
      {isShowListLanguage && (
        <ListLanguage
          languages={listDefaultLanguage}
          onClick={handleChooseLanguage}
          onCancel={() => setIsShowListLanguage(false)}
        />
      )}
    </>
  );
};

export default SelectLanguageContainer;
